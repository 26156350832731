import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseRouter from './components/SuspenseRouter';

import './styles/style.scss';

const Landing = lazy(()=> import("./screens/Landing"));
const QuemSomos = lazy(()=> import("./screens/QuemSomos"));
const EcoSystem = lazy(()=> import("./screens/EcoSystem"));
const Plataforma = lazy(()=> import("./screens/Plataforma"));
const Blog = lazy(()=> import("./screens/Blog"));
const BlogDetalhes = lazy(()=> import("./screens/BlogDetalhes"));
const Carreira = lazy(()=> import("./screens/Carreira"));

function App() {
  	return (
		<SuspenseRouter window={window} basename={process.env.PUBLIC_URL}>
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route path="/quemsomos" element={<QuemSomos />} />
				<Route path="/ecosystem" element={<EcoSystem />} />
				<Route path="/plataforma" element={<Plataforma />} />
				<Route path="/blog" element={<Blog />} />
				<Route path="/blogDetalhes/:id" element={<BlogDetalhes />} />
				<Route path="/carreira" element={<Carreira />} />
			</Routes>
		</SuspenseRouter>
  	);
}

export default App;
